import React, { useState, useEffect } from 'react'
import { ValidationError, useForm } from '@formspree/react'
import whiteArrow from '../images/white-arrow.svg'
import CountryList from './CountryList'
import ProductLocationList from './ProductLocationList'
import axios from "axios";
import scrollTo from 'gatsby-plugin-smoothscroll';


const productTypes = [
  {
    name: "Aqua",
    id: "Aqua",
    products: [
      {
        name: "Coil Size 10, Stainless Steel Slider & Open-Parts",
        id: "A21-01",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/coil-size-10/0%2C0%2C0/000011.png",
      },
      {
        name: "PREMEO Protect-WP 4/6 + HI-VIS",
        id: "AA305",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/premeo-protect-wp-4-6-hi-vis-v2/0%2C0%2C0/000011.png",
      },
      {
        name: "PREMEO Design 40",
        id: "AA306",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/premeo-design-40/0%2C0%2C0/000011.png",
      },
      {
        name: "QUICKBURST® PRIFA®",
        id: "A21-02",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/qbz-prifa/0%2C0%2C0/000011.png",
      },
      {
        name: "Flexseal® Robust Slider ",
        id: "A21-03",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/flexseal-robust-slider/0%2C0%2C0/000011.png",
      },
    ]
  },
  {
    name: "Comfort",
    id: "Comfort",
    products: [
      {
        name: "PREMEO Design 7 / 10",
        id: "AA300",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/premeo-design-7-10/0%2C0%2C0/000011.png",
      },
      {
        name: "Plancer®",
        id: "AA29",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/plancer-l/0%2C0%2C0/000011.png",
      },
      {
        name: "Interchangeable Coil Zipper Size 5",
        id: "A21-04",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/5-ci-musi-g/0%2C0%2C0/000011.png",
      },
      {
        name: "Vislon® Nylon",
        id: "A21-05",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/vislon-nylon-mono/0%2C0%2C0/000011.png",
      },
      {
        name: "Advancen",
        id: "AA186",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/advancen/0%2C0%2C0/000011.png",
      },
    ]
  },
  {
    name: "Evolutionary",
    id: "Evolutionary",
    products: [
      {
        name: "PREMEO Design 40 with AcroPlating®",
        id: "AA302",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/premeo-design-40-with-acroplating/0%2C0%2C0/000011.png",
      },
      {
        name: "Eyelet Snap Fastening System",
        id: "AA303",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/eyelet-snap-fastening-system/0%2C0%2C0/000011.png",
      },
      {
        name: "PREMEO Flat-S 1",
        id: "AA304",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/premeo-flat-s-1/0%2C0%2C0/000011.png",
      },
      {
        name: "Multi-Adapter",
        id: "AA310",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/multi-adapter/0%2C0%2C0/000011.png",
      },
      {
        name: "Vislon® Size 15, UV Resistant ",
        id: "A21-06",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/rds-products/0%2C0%2C0/000011.png",
      },
    ]
  },
  {
    name: "Fire",
    id: "Fire",
    products: [
      {
        name: "Advancen® DP 300 FR",
        id: "AA181",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/advancen-dp-300-fr/0%2C0%2C0/000011.png",
      },
      {
        name: "FR Tackbutton",
        id: "AA311",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/fr-tackbutton/0%2C0%2C0/000011.png",
      },
      {
        name: "Vislon® Aquaguard® with Top-Open",
        id: "A21-08",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/vislon-aquaguard-with-top-open/0%2C0%2C0/000011.png",
      },
      {
        name: "Excella® with fire retardant meta-aramid tape",
        id: "A21-07",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/excella-with-fire-retardant-meta-aramid-tape/0%2C0%2C0/000011.png",
      },
      {
        name: "Vislon® Meta-aramid with Top Open",
        id: "A21-09",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/vislon-meta-aramid-with-top-open-high-visibility/0%2C0%2C0/000011.png",
      },
    ]
  },
  {
    name: "Industrial",
    id: "Industrial",
    products: [
      {
        name: "PREMEO Protect-S 3",
        id: "AA308",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/PREMEO-Protect-S-3/0%2C0%2C0/000011.png",
      },
      {
        name: "PREMEO Protect-S 4",
        id: "AA202",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/premeo-protect-s-4/0%2C0%2C0/000011.png",
      },
      {
        name: "Stainless Steel products",
        id: "AA309",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1633511679/ykk/360s/stainless-steel-products/0%2C0%2C0/000011.png",
      },
      {
        name: "Cosmolon® Disk",
        id: "A21-10",
        image: "https://res.cloudinary.com/personatile/image/upload/f_auto,w_400,h_400,c_fill,g_north/v1611593335/ykk/360s/cosmolon-disk/0%2C0%2C0/000011.png",
      },
    ]
  },

  {
    name: "No sample",
    id: "None"
  }
]

function SampleForm() {
  const [selectedType, setSelectedType] = useState({});

  // let selectedProductIDs = JSON.parse( localStorage.getItem('productIds') || '0' )
  // if(!selectedProductIDs) {
  //   selectedProductIDs = []
  // }


  const [initialized, setInitialized] = useState(false);
  const [productIDs, setProductIDs] = useState(false);
  const [windowLoaded, setWindowLoaded] = useState(false);
  const [selectedProductsChanged, setSelectedProductsChanged] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (!windowLoaded) {
      if (typeof window !== 'undefined' && window) {
        setWindowLoaded(true)
      }
    }
  }, [windowLoaded]);

  useEffect(() => {
    if (!initialized && windowLoaded) {
      //You can now use your localStorage commands
      let selectedProductIDs = JSON.parse( localStorage.getItem('productIds') || '0' )
      if(!selectedProductIDs) {
        selectedProductIDs = []
      }
      setProductIDs(selectedProductIDs);
      setInitialized(true);
      setSelectedProducts(selectedProductIDs);
    }
  }, [initialized, windowLoaded]);

  // if(initialized) {
  //   console.log(selectedProductIDs);
  // }




  const [fieldErrors, setFieldErrors] = useState({});
  const validationRules = {
    email: val => val && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val),
    name: val => !!val,
    role: val => !!val,
    country: val => !!val,
    company: val => !!val,
    telephone: val => true,
    location: val => true,
    multiplelocations: val => true,
    accountmanager: val => true,
    address: val => !!val,
    request: val => true,
    message: val => true,
    marketingconsent: val => !!val,
  };
  const validate = () => {
    let errors = {};
    let hasErrors = false;
    for (let key of Object.keys(inputs)) {
      errors[key] = !validationRules[key](inputs[key]);
      hasErrors |= errors[key];
    }
    setFieldErrors(prev => ({ ...prev, ...errors }));
    return !hasErrors;
  };
  const renderFieldError = field => {
    if (fieldErrors[field]) {
      if(field == "marketingconsent") {
        return <p className="errorMsg">Please check this box to be contacted by YKK.</p>;

      } else {
        return <p className="errorMsg">Please enter a valid {field}.</p>;
      }
    }
  };


  // Input Change Handling
  const [inputs, setInputs] = useState({
    email: "",
    name: "",
    role: "",
    country: "",
    company: "",
    telephone: "",
    location: "",
    multiplelocations: "",
    accountmanager: "",
    request: "",
    message: "",
    address: "",
    marketingconsent: false,
  });

  useEffect(() => {
    // Only perform interactive validation after submit
    if (Object.keys(fieldErrors).length > 0) {
      validate();
    }
  }, [inputs]);
  /* End new validation ^^^^ */

  const handleOnChange = event => {
    event.persist();
    if(event.target.id == "marketingconsent") {
      setInputs(prev => ({
        ...prev,
        [event.target.id]: event.target.checked
      }));
    } else {
      setInputs(prev => ({
        ...prev,
        [event.target.id]: event.target.value
      }));
    }

  };

  // Server State Handling
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      setFieldErrors({});
      setInputs({
        email: "",
        name: "",
        role: "",
        country: "",
        company: "",
        telephone: "",
        location: "",
        multiplelocations: "",
        accountmanager: "",
        request: "",
        message: "",
        address: "",
        marketingconsent: false
      });

      setSelectedProducts([]);
      setProductIDs([]);
      localStorage.setItem('productIds', JSON.stringify([]) );
      window.dispatchEvent( new Event('storage') )
    }
  };

  const handleModalClose = event => {
    setServerState({ submitting: false, status: null })
  }
  const handleOnSubmit = event => {
    event.preventDefault();
    if (!validate()) {
      scrollTo('#form')
      return;
    }

    if(selectedType['producttype'] != "No sample") {
      let productsArr = []
      if(selectedProducts.length == 0 && productIDs.length > 0 && !selectedProductsChanged) {
        // populate products by localStorage
        productsArr = productIDs
      } else if(selectedProducts.length > 0) {
        // populate products by checkbox
        productsArr = selectedProducts
      }
      inputs['products'] = productsArr.join(', ')
    }
    if(!inputs['products']) {
      inputs['products'] = "No samples"
    }

    setServerState({ submitting: true });

    axios({
      method: "POST",
      url: "https://formspree.io/mleozkbn",
      data: inputs
    })
      .then(r => {
        handleServerResponse(true, "Thanks!");
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error);
      });
  };


  const handleProductTypeChange = e => {
    const { name, value } = e.target;


    if(value == "No sample") {
      setSelectedProducts([]);
      setProductIDs([]);
      localStorage.setItem('productIds', JSON.stringify([]) );
      window.dispatchEvent( new Event('storage') )
    }

    setSelectedType(selectedType => ({
      ...selectedType,
      [name]: value
    }));
  };

  const handleProductChange = e => {
    const { name, value } = e.target;

    let localStorageProductIDs = JSON.parse( localStorage.getItem('productIds') || '0' )
    if(!localStorageProductIDs) {
      localStorageProductIDs = []
    }

    setSelectedProductsChanged(true);
    if (selectedProducts.includes(value)) {
      localStorageProductIDs = localStorageProductIDs.filter(product => product != value)
      setSelectedProducts(selectedProducts.filter(product => product != value));
    } else {
      localStorageProductIDs = [...localStorageProductIDs, value]
      setSelectedProducts(selectedProducts => [...selectedProducts, value]);
    }

    localStorage.setItem('productIds', JSON.stringify(localStorageProductIDs) );
    window.dispatchEvent( new Event('storage') )


  };

  return (
      (initialized && productIDs) ? (
        <form className="request-a-sample" id="form" onSubmit={handleOnSubmit} noValidate>
          <div className="container">
            <div className="flex">

              <div className="request-a-sample__type">
                <p className="h5">Select a product type:</p>

                <div className="request-a-sample__select-type">
                  {
                    productTypes &&
                    productTypes.map((type, index) => (
                      <>
                        <input onChange={handleProductTypeChange} type="radio" id={type.id} name="producttype" value={type.name} checked={selectedType["producttype"] == type.name || false} />
                        <label for={type.id}>{type.name}</label>
                      </>
                    ))
                  }
                </div>
              </div>
              <div className="request-a-sample__products-details">

                <div className="request-a-sample__select-products" style={{ display: selectedType? 'flex': 'none' }}>
                {
                  productTypes &&
                  productTypes.map((type, index) => (
                    type['products'] &&
                    type['products'].map((product, i) => (
                      <div className="request-a-sample__product-wrap" style={{ display: selectedType["producttype"]==type.name? 'block': 'none' }}>
                        <input
                          onChange={handleProductChange}
                          type="checkbox"
                          value={product.id}
                          id={product.name}
                          name="products"
                          defaultChecked={productIDs.includes(product.id)}
                          checked={selectedProducts.includes(product.id)}
                        />
                        <label for={product.name}>
                          <div className="image-wrap">
                            <span className="name">{product.name}</span>
                            <img src={product.image} />
                          </div>
                          <span className="id">{product.id}</span>

                        </label>

                      </div>
                    ))

                  ))
                }
                </div>

                <article className="form-wrapper__segment">
                  <h3 className="form-wrapper__segment-title">Contact Details</h3>
                  <div className="form-wrapper__details">
                    <div className="form-wrapper__input">
                      <label htmlFor="name">Full Name<sup>*</sup></label>
                      <input type="text" id="name" name="name" onChange={handleOnChange} value={inputs.name} placeholder="Enter your full name" required />
                      {renderFieldError("name")}
                    </div>
                    <div className="form-wrapper__input">
                      <label for="name">Email Address<sup>*</sup></label>
                      <input type="email" id="email" name="email" onChange={handleOnChange} value={inputs.email} placeholder="Enter your email address"/>
                      {renderFieldError("email")}
                    </div>
                    <div className="form-wrapper__input">
                      <label for="telephone">Contact Number</label>
                      <input type="text" id="telephone" name="telephone" onChange={handleOnChange} value={inputs.telephone} placeholder="Enter your phone number"/>
                    </div>
                    <div className="form-wrapper__input">
                      <label for="country">Country<sup>*</sup></label>
                      <div className="select-wrapper">
                        <select name="country" id="country" onChange={handleOnChange} value={inputs.country} required>
                          <option value="" default>Select a country</option>
                          <CountryList />
                        </select>
                      </div>
                      {renderFieldError("country")}
                    </div>
                  </div>
                </article>
                <article className="form-wrapper__segment">
                  <h3 className="form-wrapper__segment-title">Delivery Details</h3>
                  <div className="form-wrapper__details">
                    <div className="form-wrapper__input">
                      <label for="company">Company Name<sup>*</sup></label>
                      <input type="text" id="company" name="company" onChange={handleOnChange} value={inputs.company} placeholder="Enter company name" required />
                      {renderFieldError("company")}
                    </div>
                    <div className="form-wrapper__input">
                      <label for="role">Role *</label>
                      <div className="select-wrapper">
                        <select id="role" name="role" onChange={handleOnChange} value={inputs.role} required>
                          <option value="" default>Select a role</option>
                          <option>Designer</option>
                      		<option>Product Development</option>
                      		<option>Purchase Office</option>
                      		<option>Buyer</option>
                      		<option>Other</option>
                        </select>
                      </div>
                      {renderFieldError("role")}
                    </div>
                    <div className="form-wrapper__input form-wrapper__input--full form-grid-message">
                      <label for="address">Address<sup>*</sup></label>
                      <textarea id="address" name="address" placeholder="Enter your address here" onChange={handleOnChange} value={inputs.address} required></textarea>
                    </div>
                  </div>
                </article>
                <article className="form-wrapper__segment">
                  <h3 className="form-wrapper__segment-title">Product Location</h3>
                  <div className="form-wrapper__details">
                    <div className="form-wrapper__input">
                      <label for="location">Product Location</label>
                      <div className="select-wrapper">
                        <select id="location" name="location" onChange={handleOnChange} value={inputs.location}>
                          <option value=""></option>
                          <ProductLocationList />
                        </select>
                      </div>
                    </div>
                    <div className="form-wrapper__input">
                      <label for="multiplelocations">Multiple Locations, Please List</label>
                      <input type="text" id="multiplelocations" name="multiplelocations" placeholder="Please state if in more than one location" onChange={handleOnChange} value={inputs.multiplelocations} />
                    </div>
                  </div>
                </article>
                <article className="form-wrapper__segment">
                  <h3 className="form-wrapper__segment-title">Next Steps</h3>
                  <div className="form-wrapper__details">
                    <div className="form-wrapper__input">
                      <label for="accountmanager">If you are already a YKK Customer, please tell us your account manager's name so we can pass your request to them:</label>
                      <input type="text" id="accountmanager" name="accountmanager" placeholder="Account manager" onChange={handleOnChange} value={inputs.accountmanager} />
                    </div>
                    <div className="form-wrapper__input">
                      <label for="request">Request</label>
                      <div className="select-wrapper">
                        <select id="request" name="request" onChange={handleOnChange} value={inputs.request}>
                          <option value=""></option>
                          <option>Meeting (virtual or physical)</option>
                      		<option>Samples</option>
                      		<option>General Information</option>
                      		<option>Wholesaler Contact</option>
                      		<option>Other</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-wrapper__input form-wrapper__input--full form-grid-message">
                      <label for="message">Message</label>
                      <textarea id="message" name="message" placeholder="Enter your message here" onChange={handleOnChange} value={inputs.message}></textarea>
                      <p>* S&amp;B samples will be provided attached on tape</p>
                    </div>
                    <div className="form-wrapper__input form-wrapper__input--full form-checkbox form-checkbox--terms">
                      <input className="custom-checkbox" type="checkbox" name="marketingconsent" id="marketingconsent" onChange={handleOnChange} value={inputs.marketingconsent} required />
                      <label for="marketingconsent" className="checkbox-label">By checking this box, you agree to be<br/>contacted by YKK for marketing purposes</label>
                      {renderFieldError("marketingconsent")}
                    </div>
                    <div className="form-wrapper__input form-wrapper__input--full">
                      <button type="submit" id="submit-btn" className="submit-btn request-a-sample-btn" disabled={serverState.submitting}>
                        Submit
                        <div className="request-a-sample-btn__icon request-a-sample-btn__icon--small" >
                          <img alt="Zip icon" src={whiteArrow} />
                          <svg width="52px" height="52px" viewBox="0 0 52 52">
                            <path className="zipstroke" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
                            <path className="zipdashes" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
                          </svg>
                        </div>
                      </button>

                      {(serverState.status && serverState.status.ok) ? (

                        <div className="modal modal--open">
                          <div className="modal__flex">
                            <div className="modal__inner modal__inner--black modal__inner--text">
                              <p className="h1 alt">Thank you</p>
                              <p>Thank you, we've received your inquiry and will get started on it right away.</p>
                              <button className="modal__close" onClick={handleModalClose}></button>
                            </div>
                          </div>
                        </div>

                      ) : ''}
                      {(serverState.status && !serverState.status.ok) ? (
                        <p className="errorMsg">
                          {serverState.status.msg}
                        </p>
                      ): ''}
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </form>
      ) : ''
  )
}

export default SampleForm
